import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Row, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { Steps } from "intro.js-react";
import { Menu, MenuItem, SubMenu, SidebarContent } from "react-pro-sidebar";

import managementModules from "../../../constant/managementModule";
import { updateActiveNavItem, updateMenu, updateMenuReport } from "../../../redux/sideMenu/menu.action";
import { login, logout, setIsOnboardedFlag } from "../../../redux/user/user.action";
import { setUpdateAccountPopup } from "../../../redux/config/action";
import { getInspectionPlan } from "../../../redux/config/action";
import {
  resetInspectionReportFilter,
  resetIncidentReportFilter,
  resetUnthorizedAccessFilter,
} from "../../../redux/filters/action";
import inspectionPlanConstant from "../../../constant/planConstant.json";
import { resetDropdownFilter } from "../../../redux/dropdownFilters/action";
import { setEditAlertModal } from "../../../redux/modals/action";
import { getIntroSteps } from "./introConstants";
import { SidebarMenuDiv } from "./NavListItem.styles";
import { resetDataToInitialState } from "../../../redux/deviceInformation/deviceInfo.action";
import { CacheRefs } from "../../../utils/cache/CacheRefs";
import PermissionCheck from "../../../components/PermissionCheck/PermissionCheck";
import PermissionsCheck from "../../../components/PermissionCheck/PermissionsCheck";

const { inspectionPlan } = inspectionPlanConstant;

// Navigation Menu Items Component
function NavListItem({
  history,
  updateMenu,
  updateMenuReport,
  setLogin,
  loginDetails,
  setLogout,
  inspectionPlanDetail,
  getInspectionPlan,
  isSuperAdmin,
  isAccountAdmin,
  isAccountOwner,
  isAccountViewer,
  isSiteAdmin,
  resetInspectionReportFilter,
  resetIncidentReportFilter,
  resetUnthorizedAccessFilter,
  resetDropdownFilter,
  activeNavItem,
  updateActiveNavItem,
  editForm,
  editFormSecond,
  setEditAlertModal,
  isOnboarded,
  setIsOnboardedFlag,
  resetDataToInitialState,
}) {
  const [worksiteBulkUploadShow, setWorksiteBulkUploadShow] = useState(false);
  //const [role, setRole] = useState(null);
  const { t } = useTranslation();
  const [isFreePlan, setFreePlan] = useState(true);
  const [remainingInspection, setRemainingInspection] = useState(0);
  const [maxAllowed, setMaxAllowed] = useState(0);
  const [activeLink, setactiveLink] = useState("dashboard");
  const [showIntro, setShowIntro] = useState(!isOnboarded);

  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setShowIntro(!isOnboarded);
  }, [isOnboarded]);

  const logout = () => {
    history.push("/");
    setLogin({ success: false });
    setLogout();
    localStorage.clear();
    sessionStorage.clear();
    CacheRefs.purgeCache();
  };

  useEffect(() => {
    //setRole(loginDetails ? loginDetails?.permission?.permissionName : "");
    getInspectionPlan(loginDetails && loginDetails.accountId);
  }, [loginDetails]);

  useEffect(() => {
    setFreePlan(
      (inspectionPlanDetail && inspectionPlanDetail.plan) ===
        (Object.keys(inspectionPlan) && Object.keys(inspectionPlan)[0])
    );
    setRemainingInspection(
      (inspectionPlan[inspectionPlanDetail && inspectionPlanDetail.plan] &&
        inspectionPlan[inspectionPlanDetail && inspectionPlanDetail.plan].maxAllowed) -
        (inspectionPlanDetail && inspectionPlanDetail.count)
    );
    setMaxAllowed(
      inspectionPlan[inspectionPlanDetail && inspectionPlanDetail.plan] &&
        inspectionPlan[inspectionPlanDetail && inspectionPlanDetail.plan].maxAllowed
    );
  }, [inspectionPlanDetail]);

  useEffect(() => {
    setactiveLink(activeNavItem);
    if (
      activeNavItem === "accounts" ||
      activeNavItem === "worksites" ||
      activeNavItem === "assets" ||
      activeNavItem === "users" ||
      activeNavItem === "userType" ||
      activeNavItem === "assetType"
    ) {
      setExpandedPanel("managementPanel");
    }
    if (
      activeNavItem === "addChecklist" ||
      activeNavItem === "viewChecklist" ||
      activeNavItem === "checklistTemplateTab" ||
      activeNavItem === "checklistTemplates"
    ) {
      setExpandedPanel("checklistSettingsPanel");
    }
    if (
      activeNavItem === "inspectionReports" ||
      activeNavItem === "incidentReports" ||
      activeNavItem === "unauthorizedReports" ||
      activeNavItem === "downloadStatus"
    ) {
      setExpandedPanel("reportsPanel");
    }
    if (
      activeNavItem === "billingLink" ||
      activeNavItem === "accountProfile" ||
      activeNavItem === "userProfile" ||
      activeNavItem === "systemSettings" ||
      activeNavItem === "generalSettings"
    ) {
      setExpandedPanel("settingsPanel");
    }
    if (
      activeNavItem === "errorLogs" ||
      activeNavItem === "assetstatus" ||
      activeNavItem === "deviceInfo" ||
      activeNavItem === "loginRecords"
    ) {
      setExpandedPanel("analyticsPanel");
    }
  }, [activeNavItem]);

  const [expandedPanel, setExpandedPanel] = useState(false);

  const handleAccordionChange = (panel) => {
    if (expandedPanel !== panel) {
      setExpandedPanel(panel);
    } else {
      setExpandedPanel("");
    }
  };

  const exitHandler = () => {
    setIsOnboardedFlag(true);
    setShowIntro(false);
  };

  return (
    <SidebarMenuDiv>
      <Steps
        enabled={showIntro}
        steps={getIntroSteps({ isSuperAdmin, isAccountViewer })}
        initialStep={0}
        onExit={exitHandler}
        ref={(steps) => setSteps(steps)}
        options={{ doneLabel: "Got It!", hidePrev: true }}
      />
      <SidebarContent>
        <Menu iconShape="circle">
          <MenuItem
            className={"dashboard"}
            active={activeLink === "dashboard"}
            icon={<i className="icon icon-dashboard" />}
            onClick={() => {
              if (editForm && editFormSecond) {
                history.push("/app/dashboard");
                setExpandedPanel("");
                resetInspectionReportFilter();
                resetIncidentReportFilter();
                resetUnthorizedAccessFilter();
                resetDropdownFilter();
              } else {
                setEditAlertModal(true);
              }
            }}
          >
            <span title="Dashboard">
              <Trans>Dashboard</Trans>
            </span>
          </MenuItem>

          {/* Analytics */}

          <PermissionCheck section={"DASHBOARD"} permissionName={"ANALYTICS"} actionName={"view"}>
            <MenuItem
              className={"analyticsPage"}
              active={activeLink === "analyticsPage"}
              icon={<i className="icon icon-analytics" />}
              onClick={() => {
                if (editForm && editFormSecond) {
                  history.push("/app/analytics");
                  setExpandedPanel("");
                } else {
                  setEditAlertModal(true);
                }
              }}
            >
              <Trans>Analytics</Trans>
            </MenuItem>
          </PermissionCheck>

          <PermissionCheck section={"DASHBOARD"} permissionName={"REPORTS"} actionName={"view"}>
            <SubMenu
              className={"reports"}
              title={t("Reports")}
              icon={<i className="icon icon-reports" />}
              onOpenChange={() => handleAccordionChange("reportsPanel")}
              open={expandedPanel === "reportsPanel"}
            >
              <MenuItem
                // innerSubMenuArrows={true}
                active={activeLink === "inspectionReports"}
                onClick={() => {
                  if (editForm && editFormSecond) {
                    history.push("/app/report");
                    resetInspectionReportFilter();
                    resetDropdownFilter();
                    updateActiveNavItem("inspectionReports");
                    updateMenuReport({ section: "report", subSection: 0 });
                  } else {
                    setEditAlertModal(true);
                  }
                }}
              >
                <span title="Inspection Reports">
                  <Trans>Inspection Reports</Trans>
                </span>
              </MenuItem>
              <MenuItem
                active={activeLink === "incidentReports"}
                onClick={() => {
                  if (editForm && editFormSecond) {
                    history.push("/app/report");
                    resetIncidentReportFilter();
                    resetDropdownFilter();
                    updateActiveNavItem("incidentReports");
                    updateMenuReport({ section: "report", subSection: 1 });
                  } else {
                    setEditAlertModal(true);
                  }
                }}
              >
                <span title="Incident Reports">
                  <Trans>Incident Reports</Trans>
                </span>
              </MenuItem>
              {/*  <MenuItem
                active={activeLink === "unauthorizedReports"}
                onClick={() => {
                  if (editForm && editFormSecond) {
                    history.push("/app/report");
                    // resetIncidentReportFilter();
                    resetUnthorizedAccessFilter();
                    resetDropdownFilter();
                    updateActiveNavItem("unauthorizedReports");
                    updateMenuReport({ section: "report", subSection: 2 });
                  } else {
                    setEditAlertModal(true);
                  }
                }}
              >
                <span title="Unauthorized Access">
                  <Trans>Unauthorized Access</Trans>
                </span>
              </MenuItem> */}
              <PermissionCheck section={"DASHBOARD"} permissionName={"DOWNLOAD_STATUS"} actionName={"view"}>
                <MenuItem
                  active={activeLink === "downloadStatus"}
                  onClick={() => {
                    if (editForm && editFormSecond) {
                      history.push("/app/report");
                      // resetIncidentReportFilter();
                      //   resetUnthorizedAccessFilter();
                      resetDropdownFilter();
                      updateActiveNavItem("downloadStatus");
                      updateMenuReport({ section: "report", subSection: 3 });
                    } else {
                      setEditAlertModal(true);
                    }
                  }}
                >
                  <span title="Download Status">
                    <Trans>Download Status</Trans>
                  </span>
                </MenuItem>
              </PermissionCheck>
            </SubMenu>
          </PermissionCheck>

          <PermissionCheck section={"DASHBOARD"} permissionName={"INSPECT_ASSET"} actionName={"view"}>
            <MenuItem
              className={"inspectAsset"}
              active={activeLink === "inspectAsset"}
              icon={<i className="icon icon-inspections-performed" />}
              onClick={() => {
                if (editForm && editFormSecond) {
                  history.push("/app/inspectAsset");
                  setExpandedPanel("");
                } else {
                  setEditAlertModal(true);
                }
                resetDropdownFilter();
              }}
            >
              <span title="Inspect Asset">
                <Trans>Inspect Asset</Trans>
              </span>
            </MenuItem>
          </PermissionCheck>

          <PermissionsCheck
            section="DASHBOARD"
            permissionNames={[
              "WORKSITE_MANAGEMENT",
              "ASSET_TYPE_MANAGEMENT",
              "ASSET_MANAGEMENT",
              "USER_TYPE_MANAGEMENT",
              "USER_MANAGEMENT",
            ]}
            actionName="view"
            operator="OR"
          >
            <SubMenu
              title={t("Management")}
              className={"introManagement"}
              icon={<i className="icon icon-management" />}
              onOpenChange={() => handleAccordionChange("managementPanel")}
              open={expandedPanel === "managementPanel"}
            >
              {managementModules.map((module, i) => {
                const { permissionName, actionName, label, relativePath, id } = module;
                return (
                  <PermissionCheck
                    section={"DASHBOARD"}
                    permissionName={permissionName}
                    actionName={actionName}
                    key={i}
                  >
                    <MenuItem
                      active={activeLink === id}
                      onClick={() => {
                        if (id?.toLowerCase?.() === activeLink) {
                          return;
                        }
                        if (editForm && editFormSecond) {
                          history.push(relativePath);
                          updateActiveNavItem(id?.toLowerCase?.());
                          updateMenu({ section: "management", subSection: i });
                        } else {
                          setEditAlertModal(true);
                        }
                      }}
                    >
                      <span title={label}>
                        <Trans>{label}</Trans>
                      </span>
                    </MenuItem>
                  </PermissionCheck>
                );
              })}
            </SubMenu>
          </PermissionsCheck>
          {/* Checklist Settings */}

          <PermissionCheck section={"DASHBOARD"} permissionName={"CHECKLIST_SETTINGS"} actionName={"view"}>
            <SubMenu
              className={"introChecklistSettings"}
              title={t("Checklist Settings")}
              icon={<i className="icon icon-checklists" />}
              onOpenChange={() => handleAccordionChange("checklistSettingsPanel")}
              open={expandedPanel === "checklistSettingsPanel"}
            >
              <MenuItem
                active={activeLink === "viewChecklist"}
                onClick={() => {
                  if (editForm && editFormSecond) {
                    history.push("/app/checklistmanagement");
                    setactiveLink("viewChecklist");
                  } else {
                    setEditAlertModal(true);
                  }
                  resetDropdownFilter();
                }}
              >
                <span title="View Your Checklists">
                  <Trans>View Your Checklists</Trans>
                </span>
              </MenuItem>

              <PermissionCheck section={"DASHBOARD"} permissionName={"CHECKLIST_SETTINGS"} actionName={"add"}>
                <MenuItem
                  active={activeLink === "checklistTemplates"}
                  onClick={() => {
                    if (editForm && editFormSecond) {
                      history.push("/app/checklistmanagement/templates");
                      setactiveLink("checklistTemplates");
                    } else {
                      setEditAlertModal(true);
                    }
                    resetDropdownFilter();
                  }}
                >
                  <span title="Add Checklist from Templates">
                    <Trans>Add Checklist from Templates</Trans>
                  </span>
                </MenuItem>
              </PermissionCheck>
            </SubMenu>
          </PermissionCheck>

          <PermissionsCheck
            section="DASHBOARD"
            permissionNames={["ERROR_LOGS", "DEVICE_INFORMATION", "LOGIN_RECORDS"]}
            actionName="view"
            operator="OR"
          >
            <SubMenu
              className={"introAssetDiagnostics"}
              open={expandedPanel === "analyticsPanel"}
              title={"Asset Diagnostics"}
              icon={<i className="icon icon-diagnostics" />}
              onOpenChange={() => handleAccordionChange("analyticsPanel")}
            >
              <PermissionCheck
                section={"DASHBOARD"}
                permissionName={"DEVICE_INFORMATION"}
                actionName={"view"}
              >
                <MenuItem
                  active={activeLink === "deviceInfo"}
                  onClick={() => {
                    resetDataToInitialState();
                    history.push("/app/device-information");
                    setactiveLink("deviceInfo");
                    resetDropdownFilter();
                  }}
                >
                  <span title="Device Information">
                    <Trans>Device Information</Trans>
                  </span>
                </MenuItem>
              </PermissionCheck>

              <PermissionCheck section={"DASHBOARD"} permissionName={"ERROR_LOGS"} actionName={"view"}>
                <MenuItem
                  active={activeLink === "errorLogs"}
                  onClick={() => {
                    history.push("/app/error-logs");
                    setactiveLink("errorLogs");
                  }}
                >
                  <span title="Error Logs">
                    <Trans>Error Logs</Trans>
                  </span>
                </MenuItem>
              </PermissionCheck>

              <PermissionCheck section={"DASHBOARD"} permissionName={"LOGIN_RECORDS"} actionName={"view"}>
                <MenuItem
                  active={activeLink === "loginRecords"}
                  onClick={() => {
                    history.push("/app/login-records");
                    setactiveLink("loginRecords");
                  }}
                >
                  <span title="Login Records">
                    <Trans>Login Records</Trans>
                  </span>
                </MenuItem>
              </PermissionCheck>
            </SubMenu>
          </PermissionsCheck>
          {/*   )} */}
          <SubMenu
            className={"introSettings"}
            open={expandedPanel === "settingsPanel"}
            title={t("Settings")}
            icon={<i className="icon icon-settings" />}
            onOpenChange={() => handleAccordionChange("settingsPanel")}
          >
            <PermissionCheck section={"DASHBOARD"} permissionName={"SYSTEM_SETTINGS"} actionName={"view"}>
              <MenuItem
                active={activeLink === "generalSettings"}
                onClick={() => {
                  if (editForm && editFormSecond) {
                    history.push("/app/general-setting");
                  } else {
                    setEditAlertModal(true);
                  }
                }}
              >
                <span title="General Settings">
                  <Trans>General Settings</Trans>
                </span>
              </MenuItem>
            </PermissionCheck>
            <PermissionCheck section={"DASHBOARD"} permissionName={"SYSTEM_SETTINGS"} actionName={"view"}>
              <MenuItem
                active={activeLink === "systemSettings"}
                onClick={() => {
                  if (editForm && editFormSecond) {
                    history.push("/app/system-setting");
                  } else {
                    setEditAlertModal(true);
                  }
                }}
              >
                <span title="System Settings">
                  <Trans>System Settings</Trans>
                </span>
              </MenuItem>
            </PermissionCheck>
          </SubMenu>
          {/* Help Center */}
          <MenuItem
            icon={<i className="icon icon-help-center" />}
            onClick={() => window.open("https://support.siera.ai/kb")}
            className={"introHelpCenter"}
          >
            <span title="Help Center">
              <Trans>Help Center</Trans>
            </span>
          </MenuItem>
          {/* Logout */}
          <MenuItem
            icon={<i className="icon icon-logout" />}
            onClick={() => (editForm && editFormSecond ? logout() : setEditAlertModal(true))}
            className={"introLogout"}
          >
            <span title="Logout">
              <Trans>Logout</Trans>
            </span>
          </MenuItem>
          {/* Inpsection Plan */}
          <MenuItem>
            {isFreePlan && (
              <span className="m-2 text-center">
                <Trans>Inspection Remaining</Trans>:
                {remainingInspection <= 0
                  ? `0`
                  : `${remainingInspection}/
                  ${maxAllowed}`}
                <p className="text-muted">
                  <Trans>The inspection limit will renew next month</Trans>.
                </p>
                <span className="btn btn-primary border-0" onClick={() => history.push("/app/billing")}>
                  <Trans>Upgrade to Pro</Trans>
                </span>
              </span>
            )}
          </MenuItem>
        </Menu>
      </SidebarContent>

      <WoksiteBulkUploadModal
        show={worksiteBulkUploadShow}
        onHide={() => setWorksiteBulkUploadShow(false)}
        toogleModalClose={() => setWorksiteBulkUploadShow(false)}
      />
    </SidebarMenuDiv>
  );
}

function WoksiteBulkUploadModal(props) {
  // const classes = useStyles();
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ padding: "200px", height: "500px" }}
    >
      <Modal.Body>
        <Row className="pl-4 pr-4 justify-content-center">
          <h4 className="text-style">
            <Trans>This feature will be here soon, kindly contact</Trans>{" "}
            <a href="mailto:support@siera.ai">support@siera.ai</a>
            <Trans>for more information. </Trans>
          </h4>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  inspectionPlanDetail: state.config.inspectionPlan,
  isAccountAdmin: state.user.isAccountAdmin,
  isAccountOwner: state.user.isAccountOwner,
  isSiteAdmin: state.user.isSiteAdmin,
  isAccountViewer: state.user.isAccountViewer,
  isSuperAdmin: state.user.isSuperAdmin,
  activeNavItem: state.sideMenu.activeNavItem,
  editForm: state.forms.editForm,
  editFormSecond: state.forms.editFormSecond,
  isOnboarded: state.user?.loginDetails?.userData?.isOnboarded,
});

const mapDispatchToProps = (dispatch) => ({
  updateMenu: (menuState) => dispatch(updateMenu(menuState)),
  setLogin: (loginData) => dispatch(login(loginData)),
  setLogout: () => dispatch(logout()),
  updateMenuReport: (menuDetails) => dispatch(updateMenuReport(menuDetails)),
  setUpdateAccountPopup: (status) => dispatch(setUpdateAccountPopup(status)),
  getInspectionPlan: (accountId) => dispatch(getInspectionPlan(accountId)),
  resetInspectionReportFilter: () => dispatch(resetInspectionReportFilter()),
  resetIncidentReportFilter: () => dispatch(resetIncidentReportFilter()),
  resetUnthorizedAccessFilter: () => dispatch(resetUnthorizedAccessFilter()),
  resetDropdownFilter: () => dispatch(resetDropdownFilter()),
  updateActiveNavItem: (data) => dispatch(updateActiveNavItem(data)),
  setEditAlertModal: (data) => dispatch(setEditAlertModal(data)),
  setIsOnboardedFlag: (data) => dispatch(setIsOnboardedFlag(data)),
  resetDataToInitialState: (data) => dispatch(resetDataToInitialState()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavListItem));
